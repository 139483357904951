
const caseStudyData = [
  {
    title: 'Local Basket',
    sub_title: 'UI/UX APP DESIGN',
    short_description:
      'An app that helps users place orders for produce and goods from vendors at their local farmer’s market.',
    link: `local-basket`,
    image_name: 'https://bz-assets.s3.us-west-2.amazonaws.com/local_basket/processed_pngs/iphone14.png',
    alt_tag: 'iphones'
  },
  {
    title: 'Sephora',
    sub_title: 'UI/UX FEATURE',
    short_description:
    'A website feature that allows users to mail their old or unwanted cosmetics and packaging to Sephora for recycling.',
    link: `sephora`,
    image_name: 'https://bz-assets.s3.us-west-2.amazonaws.com/sephora/processed_pngs/macbook_air.png',
    alt_tag: 'iphones'
  },
  {
    title: 'Guilder',
    sub_title: 'UI/UX DESIGN',
    short_description: 'A Princess Bride themed coffee shop in Portland, Oregon in need of a new design including an online ordering system.',
    link: `guilder`,
    image_name: 'https://bz-assets.s3.us-west-2.amazonaws.com/guilder/processed+pngs/iphone_14.png',
    alt_tag: 'iphones'
  },
  {
    title: 'Cleverist',
    sub_title: 'END-TO-END DESIGN',
    short_description:
      'A responsive website where parents and caregivers can find educational activities to do with their children at home.',
    link: `cleverist`,
    image_name: 'https://bz-assets.s3.us-west-2.amazonaws.com/cleverist/processed_pngs/iPhone_14.png',
    alt_tag: 'iphones'
  },
];

export default caseStudyData;
